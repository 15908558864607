<template>
  <div class="bgimg">
    
    <div class="rowdark">
      <global-Banner :pagename=pagename /> <!-- Links to src/global/Banner.vue -->
    </div>

    <div class="bglayer">
    
      <v-container>
          <local-industry-header /> <!-- Links to src/local/industry/header.vue -->
      </v-container>

      <local-industry-r1/>
      
      <local-industry-r2/>

      <local-industry-r3/>
      
      <local-industry-defence/>

    </div>

  </div>
</template>


<script>

  export default {
    name: 'industry',
    data() {
      return {
        pagename: 'capability'
      }
    }
  }
</script>


<style scoped>

.bgimg {
  background: url('../assets/images/industry/city.jpeg') no-repeat center top;
  background-size: cover;
}

.bglayer{
  background: linear-gradient(to bottom, var(--v-whiteblack-base), var(--v-whiteblack-base) 10%, transparent, transparent 150%);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rowdark {
  background-color: var(--v-bgdark-base);
  padding: 150 px;
}

</style>